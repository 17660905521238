<template>
    <workspace-wrapper>
        <template #workspace>
            <JokerWildWorkspace :state-data="stateData"/>
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import JokerWildWorkspace from './JokerWildWorkspace.vue';

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        cards: [{
                            suit: null,
                            cost: null
                        }],
                        lockedCards: []
                    }
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        JokerWildWorkspace
    }
}
</script>

<style scoped>

</style>
